export const groups = process.env.REACT_APP_DEMO_GROUPS
  ? [
      // Demo groups
      {
        value: 'mika.maunula@gapps.fi',
        label: 'Test Group 1',
      },
      {
        value: 'markus.wennerstrom@yle.fi',
        label: 'Test Group 2',
      },
      {
        value: 'ville.kotonen@yle.fi',
        label: 'Test Group 3',
      },
      {
        value: 'tuomas.lahteenmaki@gapps.fi',
        label: 'Test Group 4',
      },
      {
        value: 'timo.rajakangas@gapps.fi',
        label: 'Test Group 5',
      },
      {
        value: 'tony.asplund@gapps.fi',
        label: 'Test Group 6',
      },
      {
        value: 'joose.hakosalo@gapps.fi',
        label: 'Test Group 7',
      },
      {
        value: 'jaakko.pohja@gapps.fi',
        label: 'Test Group 8',
      },
      {
        value: 'miko.koivula@gapps.fi',
        label: 'Test Group 9',
      }
    ]
  : [
      // Production groups
      {
        value: 'ua_assistentit@yle.fi',
        label: 'Uutis- ja ajankohtaistoiminta',
      },
      {
        value: 'sv_adminteam@yle.fi',
        label: 'Svenska Yle',
      },
      {
        value: 'maksut.yleurheilu@yle.fi',
        label: 'Yle Urheilu',
      },
      {
        value: 'tapahtumat_maksut@yle.fi',
        label: 'Yle Tapahtumat',
      },
    ];

export const visitorTypes = [
  {
    value: 'visitor',
  },
  {
    value: 'purchaseFee',
  },
];

export const specialGroup = {
  value: 'special_group',
  label: 'Luovat sisällöt ja media',
};

export const getTotalAmount = (expense) => {
  const expenses = (expense.expenses || []).filter(
    (e) => e.type !== 'kilometreAllowance',
  );
  if (expenses.length) {
    const v = expenses.reduce((sum, cur) => sum + cur.amount, 0);

    return new Intl.NumberFormat('fi-FI', {
      style: 'currency',
      currency: 'EUR',
    }).format(v);
  }
  return '-';
};

export const getTotalDistance = (expense) => {
  const expenses = (expense.expenses || []).filter(
    (e) => e.type === 'kilometreAllowance',
  );
  if (expenses.length) {
    // Exclude kilometreAllowances
    const v = expenses.reduce((sum, cur) => sum + cur.amount, 0);

    return v + ' Km';
  }
  return '-';
};

export const getGroup = (group) => {
  const g = groups.find((g) => g.value === group);
  if (g) return g.label;
  return group;
};

export const getGroupEmails = (string) => {
  return groups
    .filter((group) => {
      if (group.label) {
        return group.label
          .toLowerCase()
          .includes(string.toLowerCase());
      }
      return false;
    })
    .map((group) => group?.value);
};

export const sanitizeName = (filename) =>
  filename
    .replace(/[äåÄÅ]/g, 'a')
    .replace(/[öÖ]/g, 'o')
    .replace(/\s/g, '_');
